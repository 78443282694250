import React from "react"
import { graphql } from 'gatsby'

import Body from "../components/editorial/body";
import Container from '../components/layout/container'
import SoMeShare from '../components/SoMeShare'
import SEO from '../components/seo'

export default function PageTemplate({ path, data }) {
 
  const pageData = data ? data.sanityStory || data.sanityStoryFromProfessional || data.sanityPost : null

  const videourl = pageData && pageData.videourl

  const isYouTubeVideo = videourl && videourl
    .split('/')
    .join()
    .split('.')
    .indexOf('youtube') > -1

  const isVimeoVideo = videourl && videourl
    .split('/')
    .join()
    .indexOf('vimeo.com') > -1

  const vimeoVideoId = videourl && videourl
    .split('/')[videourl.split('/').length - 1]

  const youtubeVideoId = videourl && videourl
    .split('/')
    .join()
    .split('=')[videourl.split('/').join().split('=').length - 1]


  return (
    <Container>

      <SEO 
        title={pageData.displayTitle ? pageData.displayTitle : pageData.title}
        description={pageData.intro ? pageData.intro : ''}
        pathname={`${data.sanitySiteSettings.url}${path}`}
        image={pageData.mainImage && pageData.mainImage.asset && pageData.mainImage.asset.fixed ? pageData.mainImage.asset.fixed : null}
      />

      <div className="container">

        {videourl &&

          <div className="mb-3 auto-resizable-iframe">

            <figure className="image is-16by9">
              
              <iframe 
                className="has-ratio"
                src={isVimeoVideo 
                  ? `https://player.vimeo.com/video/${vimeoVideoId}`
                  : isYouTubeVideo 
                    ? `https://www.youtube.com/embed/${youtubeVideoId}`
                    : null}
                title='Video'
                width="485" 
                height="292" 
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen
              />

            </figure>

          </div>
          
        }

        {!videourl && pageData.mainImage && pageData.mainImage.asset.fixed && 
          <img src={pageData.mainImage.asset.fixed.src} alt="" />
        }

        <div className="section py-0 mb-6">
          
          <div className="columns">
            <div className="column is-offset-2 is-8 mt-6">

              {pageData.title || pageData.displayTitle ?
                <h1 className="title is-size-1">{pageData.displayTitle ? pageData.displayTitle : pageData.title}</h1>
              : null}

              {pageData.intro && <p className="is-size-4">{pageData.intro}</p>}

              {pageData._rawBody && 
                <Body blocks={pageData._rawBody} />
              }

              <div 
                style={{
                  'paddingTop': '1em',
                  'marginTop': '3em',
                  'borderTop': '1px solid #000'
                }}
                className="is-flex is-justify-content-space-between">
                  {(pageData.author || pageData.publishedAt) && 
                    <div>
                      <span style={{'display': 'block'}}>
                        {pageData.author && `Av ${pageData.author.name}`}
                      </span>
                      <span style={{'display': 'block'}}>
                        {pageData.publishedAt && `Publisert ${pageData.publishedAt}`}
                      </span>
                    </div>
                  }
                  <div>
                    <SoMeShare 
                      title={pageData.title}
                      url={`${data.sanitySiteSettings.url}/aktuelt/${pageData.slug.current}`}
                    />
                  </div>
              </div>
            
            </div>
          </div>

        </div>

        
      </div>

    </Container>
  )
}

export const query = graphql`
  query SanityStory($slug: String!) {
    sanitySiteSettings {
      url
    }
    sanityPost(slug: {current: {eq: $slug}}) {
      title
      _rawBody
      slug {
        current
      }
      publishedAt(formatString: "DD.MM.YYYY")
      mainImage {
        asset {
          fixed(width: 1300, height: 710) {
            src
            width
            height
          }
        }
      }
      author {
        name
      }
    }
    sanityStoryFromProfessional(slug: {current: {eq: $slug}}) {
      title
      displayTitle
      intro
      slug {
        current
      }
      _rawBody
      publishedAt(formatString: "DD.MM.YYYY")
      videourl
      mainImage {
        asset {
          fixed(width: 1300, height: 710) {
            src
            width
            height
          }
        }
        alt
        caption
        attribution
      }
      author {
        name
      }
    }
    sanityStory(slug: {current: {eq: $slug}}) {
      title
      displayTitle
      intro
      slug {
        current
      }
      _rawBody
      publishedAt(formatString: "DD.MM.YYYY")
      videourl
      mainImage {
        asset {
          fixed(width: 1300, height: 710) {
            src
            width
            height
          }
        }
        alt
        caption
        attribution
      }
      author {
        name
      }
    }
  }
`