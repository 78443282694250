import React from 'react';
import { graphql, StaticQuery } from "gatsby";

import Facebook from "./../icons/Facebook";
import Twitter from "./../icons/Twitter";
import Email from "./../icons/email.svg";

import Link from "./editorial/link";

const SoMeShare = ({title,url}) => (

  <StaticQuery
    query={graphql`
      query {
        sanitySiteSettings {
          facebook
          twitter
      }
    }
    `}
    render={data => (
      <div className="is-flex">

        <Link 
          className="button mr-4 p-2 circle" 
          linkText={<span className="icon"><Facebook fill={'white'} /></span>} 
          externalURL={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        />

        <Link 
          className="button mr-4 p-2 circle" 
          linkText={<span className="icon"><Twitter fill={'white'} /></span>}
          externalURL={`http://twitter.com/share?text=${title}&url=${url}`}
        />

        <Link 
          className="button mr-4 p-2 circle" 
          linkText={<span className="icon"><Email fill={'white'} /></span>}
          externalURL={`mailto:?subject=${title}&body=${url}`}
        />

      </div>
    )}
  />
            
);
export default SoMeShare;
